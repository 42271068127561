<template>
	<div class="adomany">
		<div class="promoPaymentMethodHeader">
			<div class="grid">
				<div class="col-3 md:col-3 lg:col-2 lead leadHeader">
				</div>
			</div>
		</div>

		<div class="grid justify-content-center">
			<div class="col-8 md:col-8 lg:col-8">
				<div class="paymentText">Sajnáljuk, de hiba történt az átutalás közben! <a href="/adomany">Kérjük próbálja meg újra!</a></div>
			</div>
		</div>

		<div class="grid justify-content-center lineHeight">
			<div class="col-10 md:col-10 lg:col-10 lineType">
			</div>
		</div>		

		<div class="grid">
			<div class="col-12 md:col-12 lg:col-12 adomanyFooter">
				<AppFooter />
			</div>
		</div>
    </div>
</template>

<script>
import AppFooter from '../AppFooter.vue';
export default {
    components: {
        'AppFooter': AppFooter,
    },
	mounted() {
		this.$appState.myCart = true;
	},
}
</script>

<style lang="scss" scoped>

</style>
